import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product, ProductCategory } from 'definitions';
// import * as jsonproductcategories from '../../../jdata/product-categories.json';
import { EnvService } from 'app/env.service';
import { TranslateService } from 'app/services/translate.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {
  @Input() product: Product;
  @Output() callbackProductItem = new EventEmitter<Product>();
  baseUrl: string;
  categories: ProductCategory[];
  currentLang: string;

  constructor(private env: EnvService, private langService: TranslateService) {
    this.baseUrl = env.APP_BASE_HREF;
  }

  ngOnInit() {
    this.currentLang = this.langService.getCurrentLang();
  }

  getBackgroundImage() {
    return '/assets/img/products/' + this.product.productPath + '/' + this.product.imageThumbPath;
  }

  goToProductPage() {
    this.callbackProductItem.emit(this.product);
  }

}
