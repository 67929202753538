import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

// import { ComponentsModule } from './components/components.module';
// import { ExamplesModule } from './examples/examples.module';
import { EnvServiceProvider } from './env.service.provider';
import { APP_BASE_HREF } from '@angular/common';
import { environment } from 'environments/environment';
import { ProductsComponent } from './shared/product-contenitor/products/products.component';
import { LandingComponent } from './shared/landing/landing.component';
import { ProductCardComponent } from './shared/product-contenitor/product-card/product-card.component';
import { HttpClientModule } from '@angular/common/http';

import { NgxPaginationModule } from 'ngx-pagination';
import { FilterProductsPipe } from './shared/elements/filter/filter-products.pipe';
import { MvSelectComponent } from './shared/elements/mv-select/mv-select.component';
import { ProductCategoriesComponent } from './shared/product-contenitor/product-categories/product-categories.component';
import { ProductDetailComponent } from './shared/product-contenitor/product-detail/product-detail.component';
import { LoadingScreenComponent } from './shared/elements/loading-screen/loading-screen.component';
// import { NgImageSliderModule } from 'ng-image-slider';
import { ProductCertificationsComponent } from './shared/product-contenitor/product-certifications/product-certifications.component';
import { ContactsComponent } from './shared/contacts/contacts/contacts.component';
import { AboutComponent } from './shared/about/about.component';
import { PartnersComponent } from './shared/about/partners/partners.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslatePipe } from './pipes/translate.pipe';
import { RdComponent } from './shared/rd/rd.component';

const imports = [
  BrowserModule,
  NgbModule.forRoot(),
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  // ComponentsModule,
  // ExamplesModule,
  AppRoutingModule,
  // RouterModule.forRoot(appRoutes),
  HttpClientModule,
  NgxPaginationModule,
  BrowserAnimationsModule
  // NgImageSliderModule
  // AngularFontAwesomeModule,
  // MaterialModule,
  // MomentModule,
  // TranslateModule.forRoot({
  //   loader: {
  //     provide: TranslateLoader,
  //     useFactory: HttpLoaderFactory,
  //     deps: [HttpClient]
  //   }
  // }),
  // SimpleNotificationsModule.forRoot()
];

const entryComponents = [
  NavbarComponent,
  FooterComponent,
  ProductsComponent,
  LandingComponent
];


const providers = [
  EnvServiceProvider,
  {
    provide: APP_BASE_HREF,
    useValue: environment.BASE_HREF
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LandingComponent,
    FooterComponent,
    ProductsComponent,
    ProductCardComponent,
    FilterProductsPipe,
    MvSelectComponent,
    ProductCategoriesComponent,
    ProductDetailComponent,
    LoadingScreenComponent,
    ProductCertificationsComponent,
    ContactsComponent,
    AboutComponent,
    PartnersComponent,
    RdComponent,
    TranslatePipe
  ],
  imports,
  providers,
  entryComponents,
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
