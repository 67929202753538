<nav class="navbar navbar-expand-lg fixed-bottom" [ngClass]="{'is-home': isHome()}" *ngIf="!preloading">

    <ul class="navbar-nav" *ngIf="showNavbar">
        <li class="nav-item">
            <!-- [routerLinkActive]="['is-active']" -->
            <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/home']" class="nav-link is-mobile">
                <!-- <i class="fas fa-home"></i> -->
                <label class="menu-label">{{ "HOME" | translate }}</label>
            </a>
        </li>
        <li class="nav-item">
            <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/about']" class="nav-link is-mobile">
                <!-- <i class="fas fa-ellipsis-h"></i> -->
                <label class="menu-label">{{ "COMPANY" | translate }}</label> 
            </a>
        </li>
        <li class="nav-item">
            <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/products']" class="nav-link is-mobile">
                <!-- <i class="fas fa-fish"></i> -->
                <label class="menu-label">{{"PRODUCTS" | translate}}</label>
            </a>
        </li>
        <li class="nav-item">
            <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/researchdevelopment']" class="nav-link is-mobile">
                <!-- <i class="fas fa-fish"></i> -->
                <label class="menu-label">{{"RD" | translate}}</label>
            </a>
        </li>
        <li class="nav-item">
            <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/contacts']" class="nav-link is-mobile">
                <!-- <i class="fas fa-envelope"></i> -->
                <label class="menu-label">{{"CONTACTS" | translate}}</label>
            </a>
        </li>
    </ul>

    <div [scrollTop]="lastScrollTop" #scrollMenu  class="container">
        <!-- [scrollTop]="lastScrollTop" [@menuOpenClose]="{value: openMenu ? 'menu-open' : 'menu-close', params: {lastScrollTop: lastScrollTop}}" -->
        <!-- {{lastScrollTop | json}} -->
         <!-- *ngIf="showNavbar" -->
         <div class="header-logo hide-xs" *ngIf="isHome()">
            <div class="logo-box-text" >
                <h1>{{"HP_TITLE" | translate}}</h1>
                <h3>{{"MOTTO" | translate}}</h3>
            </div>
            <!-- (click)="openMenu ? openMenu = false : openMenu = true"" -->
            <div class="btn-back-container" (click)="scrollBottom();">
                <a class="goto-menu"><div></div></a>
                <a class="goto-menu"><div></div></a>
               <a class="goto-menu"><div></div></a>
            </div>
         </div>
         
         <div class="btn-mobile-menu only-xs" (click)="toggleMobileMenu()">
            <i class="fas fa-bars"></i>
            <label class="navbar-brand">{{getTitlePageActive()}}</label>
         </div>

        <div class="logo-box-image only-xs" [ngClass]="{'is-home': isHome()}">
            <a (click)="closeMobileMenu()" [routerLink]="['/home']">
                <img alt="logo monteverde e vallese" src="/assets/img/logo.png"/>
            </a>
        </div>
        <app-footer *ngIf="isHome()" class="only-xs" style="width: 100%;position: relative;"></app-footer>

        
 <!-- [@menuOpenClose]="openMenu ? 'menu-open' : 'menu-close'"  (@extMenuOpenClose.start)="onAnimationEvent($event)"  -->
            <!-- (@extMenuOpenClose.done)="onAnimationEvent($event)" -->
        <!-- *ngIf="!showNavbar" -->
        <div class="menu hide-xs row" [ngClass]="{ 'fix-menu' : isHome() }">
            <!-- <a class="icon icon-menu" (click)="toggleMobileMenu()">
                <i class="fa fa-bars"></i>
            </a> -->
            <!-- [ngClass]="{ 'col-md-3' : isHome(), 'col-md-4' : !isHome()  }" -->
            <div class="nav-item col-md-2">
                <!-- [routerLinkActive]="['is-active']" class="no-hover nav-link" -->
                <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/home']" class="nav-link">
                    <!-- <i class="fas fa-home"></i> -->
                    <img alt="home page" src="/assets/img/icons/home.png" />
                    <label class="menu-label">{{ "HOME" | translate }}</label>
                </a>
            </div>
            <!-- [ngClass]="{ 'col-md-3' : isHome(), 'col-md-4' : !isHome()  }" -->
            <div class="nav-item col-md-2">
                <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/about']" class="nav-link">
                    <!-- <i class="fas fa-ellipsis-h"></i> -->
                    <img alt="corporate" src="/assets/img/icons/corporate.png" />
                    <label class="menu-label">{{ "COMPANY" | translate }}</label>
                </a>
            </div>
            <!-- [ngClass]="{ 'col-md-3' : isHome(), 'col-md-4' : !isHome()  }" -->
            <div class="nav-item col-md-2">
                <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/products']" class="nav-link">
                    <!-- <i class="fas fa-fish"></i> -->
                    <img alt="products" src="/assets/img/icons/products.png" />
                    <label class="menu-label">{{"PRODUCTS" | translate}}</label>
                </a>
            </div>
            <div class="nav-item col-md-2">
                <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/researchdevelopment']" class="nav-link">
                    <!-- <i class="fas fa-fish"></i> -->
                    <img alt="researchdevelopment" src="/assets/img/icons/rd.png" />
                    <label class="menu-label">{{"RD" | translate}}</label>
                </a>
            </div>
            <!-- [ngClass]="{ 'col-md-3' : isHome(), 'col-md-4' : !isHome()  }" -->
            <div class="nav-item col-md-2">
                <a (click)="closeMobileMenu()" [routerLinkActive]="['is-active']" [routerLink]="['/contacts']" class="nav-link">
                    <!-- <i class="fas fa-envelope"></i> -->
                    <img alt="contacts" src="/assets/img/icons/contacts.png" />
                    <label class="menu-label">{{"CONTACTS" | translate}}</label>
                </a>
            </div>
            <!-- [ngClass]="{ 'col-md-3' : isHome(), 'col-md-4' : !isHome()  } -->
            <!-- <div class="nav-item col-md-2-3 isHome">
                <a (click)="closeMobileMenu()" [routerLink]="['/home']" [routerLinkActive]="['is-active']" class="no-hover nav-link">
                    <img src="/assets/img/logo.png"/>
                </a>
            </div> -->
            <app-footer *ngIf="isHome()" style="width: 100%;"></app-footer>

        </div>

        <!-- <div class="piva">
            <p>{{"PIVA" | translate}}</p>
         </div>   -->
    </div>

</nav>
  
<div class="loader-wrapper" [ngClass]="preloading ? '' : 'loaded'">
    <div class="loader">

    </div>
</div>