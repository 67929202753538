import { Router } from '@angular/router';
// import { MvSelectComponent } from './../elements/select/mv-select/mv-select.component';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product, ProductCategory } from 'definitions';
import { Meta, Title } from '@angular/platform-browser';
// import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
// import { ProductsService } from 'app/products.service';
import { TranslateService } from 'app/services/translate.service';
// import * as ProductsJson from 'assets/jdata/products-list.json';
// import * as ProdCategoriesJson from 'assets/jdata/product-categories.json';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {
  // @ViewChildren(MvSelectComponent) mvSelectComponents: QueryList<
  //   MvSelectComponent
  // >;
  categoriesControl = new FormControl();
  products: any[];
  // products: Product[];
  categories: any[];
  // categories: ProductCategory[];
  selectedCategories: ProductCategory[];
  page = 1;
  isLoading = true;
  currentLang: string;

  constructor(
    private http: HttpClient, 
    private router: Router, 
    private langService: TranslateService,
    private title: Title, 
    private meta: Meta) {
  }
  ngOnDestroy(): void {
    let body = document.getElementsByTagName('body')[0];
    body.className = "";
  }

  // ngAfterViewInit() {
    
  // }
  
  ngOnInit() {
    this.title.setTitle('Prodotti | Moval');
    this.meta.updateTag({ name: 'description', content: 'La nostra offerta.' });

    this.currentLang = this.langService.getCurrentLang();
    let body = document.getElementsByTagName('body')[0];
    body.className += "scrollable";
    this.reload();
  }

  reload() {
    // this.productService.getProducts().subscribe(
    //   data => {
    //     this.products = data;
    //     this.isLoading = false;
    //   },
    //   error => {
    //     console.error(error);
    //   }
    // );
    
    // this.products = JSON.parse(JSON.stringify(ProductsJson.products));
    // this.categories = JSON.parse(JSON.stringify(ProdCategoriesJson.productCategories));
    this.getProductList().subscribe(data => {
      this.products = data.products;
      this.isLoading = false;
    });
    this.getProductCategories().subscribe(data => {
      // console.log('categories', data);
      this.categories = data.productCategories;
    });
  }
  getProductCategories(): Observable<any> {
    return this.http.get('./assets/jdata/product-categories.json');
  }
  getProductList(): Observable<any> {
    return this.http.get('./assets/jdata/products-list.json');
  }

  clearFilters() {
    // this.mvSelectComponents.forEach(instance => instance.reset());
  }

  getAllCategories() {
    return this.categories.map(a => a.catID);
  }

  toggleCategoryFilter(catSelected: ProductCategory) {
    // debugger;
    if (!this.selectedCategories) {
      this.selectedCategories = new Array();
    }
    if (!this.selectedCategories.find(x => x.catID == catSelected.catID)) {
      this.selectedCategories.push(catSelected);
    } else {
      this.selectedCategories = this.selectedCategories.filter(x => x.catID !== catSelected.catID);
      // [this.selectedCategories.indexOf(catID)]
    }
    if(this.selectedCategories && this.selectedCategories.length == 0) {
      // this.selectedCategories = this.categories;
    }
    this.reload();
  }
  getCategoryNameByID(id: number) {
    return this.categories ? this.categories.find(x => x.catID === id).name : '';
  }
  callbackProductItem(productClicked: Product) {
    this.selectedCategories = new Array();
    this.router.navigateByUrl('/product', { state: productClicked });
  }
  pageChanged(event) {
    this.page = event;
  }
}
