export class Product {
    prodID: number;
    name: string;
    origin: string;
    categories: number[];
    certifications: number[];
    description: string;
    productPath: string;
    imageThumbPath: string;
    images: string[];
}

export class ProductCategory {
    catID: number;
    name: string;
    hexBgColor: string;
    description: string;
}

export class ProductCertification {
    certID: number;
    sigla: string;
    name: string;
    imagePath: string;
    description: string;
}

export class ProductImage {
    prodImgID: number;
    productPath: string;
    imagePath: string;
}

export class Contatti {
    name: string;
    email: string;
    phone: string;
    notes: string;
    constructor() {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.notes = '';
    }
}

export class Partners {
    partnerID: number;
    logoPath: string;
    name: string;
    description: string;
}