import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from 'app/env.service';
import { Contatti } from 'definitions';

@Injectable({
  providedIn: 'root'
})
export class ContattiService {

  private url = 'submit-contact.php';
  constructor(private http:HttpClient, private env: EnvService) {
  }

  performGetEx(obj: Contatti):Observable<any>{

      // return this.http.post<any>('https://www.monteverdevallese.com/be/submit-contact.php', obj);
      return this.http.post<any>(this.env.APP_BASE_HREF + '/be/' + this.url, obj);
  }
}
