<!-- <div > -->
    <app-navbar *ngIf="!isComingSoonEnabled"></app-navbar>
    <router-outlet (activate)="onActivate($event)" *ngIf="!isComingSoonEnabled"></router-outlet>
    <!-- <button class="langSelector" (click)='setLanguage("en")'>EEENN</button> -->
    <!-- <button class="langSelector2" (click)='setLanguage("it")'>IIITT</button> -->
    <!-- [ngClass]="{'show-c': !showNavbar }" -->
    <!-- <app-footer></app-footer> -->
<!-- </div> -->

<div *ngIf="isComingSoonEnabled">
    <style>
      body { text-align: center; padding: 150px; }
      h1 { font-size: 50px; }
      body { font: 20px Helvetica, sans-serif; color: #333; }
      article { display: block; text-align: left; width: 650px; margin: 0 auto; }
      a { color: #dc8100; text-decoration: none; }
      a:hover { color: #333; text-decoration: none; }
    </style>
    
    <article>
        <h1>www.monteverdevallese.com</h1>
        <div>
            <p>Site Under Construction</p>
            <p>&mdash; Il Team</p>
        </div>
    </article>
</div>
