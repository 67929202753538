import { Component, OnInit, ElementRef, HostListener, OnChanges, ViewChild } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'definitions';
import { ActivatedRoute } from '@angular/router';
import { trigger,state,style,transition,animate } from '@angular/animations';
import { TranslateService } from 'app/services/translate.service';
// import * as ProductsJson from 'assets/jdata/products-list.json';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    animations: [
        trigger('menuOpenClose', [
            state('menu-open', style({
              // right: '12.30vh'
              bottom: '500px'
            })),
            state('menu-close', style({
              bottom: '0'
            })),
            transition('* => *', [
              animate('0.5s')
            ])
          ])
        ]
})
export class NavbarComponent implements OnInit, OnChanges {
    @ViewChild('scrollMenu') private scrollMenu: ElementRef;
    
    openMenu: boolean;
    showNavbar = false;
    lastScrollTop = 0;
    isMobileMenuOpen = false;
    // pageActive: string; 
    product: Product;
    products: any[];
    // products: Product[];
    prodID: number;
    preloading = true;
    currentLang: string;

    constructor(public location: Location,
        private langService: TranslateService,
        private http: HttpClient,
        private element: ElementRef) {
            // this.products = ProductsJson.products;

            this.getProductList().subscribe(data => {
                this.products = data.products;
                console.log('prodotto in navbar: ', this.product);
            });
    }

    ngOnInit() {
        this.currentLang = this.langService.getCurrentLang();
        // console.log("init >>>>", this.lastScrollTop);
        this.lastScrollTop = 0;
        setTimeout(() => {
            this.preloading = false;
        }, 1500);
    }
    ngOnChanges() {
    }
    // @HostListener('scroll', ['$event']) // for scroll events of the current element
    @HostListener('mousewheel', ['$event']) // for window scroll events
    onMousewheel(event) {
        // console.log("event scroll", event);
        // debugger;

        var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight|| e.clientHeight|| g.clientHeight;

    var result = (y*20)/100; 
        var event = window.event || event; // old IE support
        var delta = event.deltaY;
        if(delta > 0) {
            if(this.lastScrollTop < result) {
                this.lastScrollTop += 20;
            }
        //     // this.mouseWheelUp.emit(event);
        } else if(delta < 0) {
            if(this.lastScrollTop > 0) {
                this.lastScrollTop -= 10;
            }
        //     // this.mouseWheelDown.emit(event);
        }
        // for IE
        event.returnValue = false;
        // for Chrome and Firefox
        if(event.preventDefault) {
            event.preventDefault();
        }

        // const st = event.currentTarget.scrollY;
        // if (st > this.lastScrollTop) {
        //     // downscroll code
        //     this.showNavbar = false;
        //     console.log("downscroll", st);
        // } else {
        //         // upscroll code
        //     this.showNavbar = true;
        //     console.log("upscroll", st);
        // }
        // this.lastScrollTop = st;
    }
    scrollBottom() {
        try {
            let i=1;
            let myInterval = setInterval(() => {
                if(this.lastScrollTop >= this.scrollMenu.nativeElement.scrollHeight) {
                    clearInterval(myInterval);
                }
                this.lastScrollTop = i;
                i=i+5;
            }, 10);
            // for(let i = 0; i <= this.scrollMenu.nativeElement.scrollHeight; i++) {
            //     this.lastScrollTop = this.scrollMenu.nativeElement.scrollHeight;
            // }
        } catch(err) { } 
    }

    getTitlePageActive() {
        let titlee = this.location.prepareExternalUrl(this.location.path());
        // console.log(">>> titleee", titlee);

        let dicText = {
            "en": {
                "about":"Company",
                "home": "",
                "products": "Products",
                "contacts": "Contacts"
            },
            "it": {
                "about":"L'azienda",
                "home": "",
                "products": "Prodotti",
                "contacts": "Contatti"
            }
        } ;

        if (titlee){
          titlee = titlee.slice( 1 );
        }
        if(titlee.indexOf('home') != -1)  {
            return dicText[this.currentLang][titlee];
            // return '';
        }
        if(titlee.indexOf('/') != -1 && this.products) {
            this.prodID = +titlee.slice(titlee.indexOf('/')+1);
            this.product = this.products.filter(x => x.prodID === this.prodID)[0];
            if(this.product) {
                return this.product[this.currentLang].name.length >= 16 ? this.product[this.currentLang].name.slice(0, 13) + '...' : this.product.name;
            }
            return '';
        }
        return dicText[this.currentLang][titlee];
    }
    getProductList(): Observable<any> {
        return this.http.get('./assets/jdata/products-list.json');
    }
    toggleMobileMenu() {
        this.showNavbar = !this.showNavbar;
    }
    closeMobileMenu() {
        this.showNavbar = false;
        this.lastScrollTop = 0;
        window.scrollTo(0, 0); 
    }
    
    isHome() {
      let titlee = this.location.prepareExternalUrl(this.location.path());
      if (titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if (titlee === '/home') {
            return true;
        } else {
            return false;
        }
    }
}
