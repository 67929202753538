<div class="page-header"> <!--  style="background-image: url('./assets/img/bg1.jpg');" -->
      <div class="row">

        <div class="logos mt-5" [style.backgroundImage]="rdBackground">

        </div>
        <div class="motto text-center col-md-12 p-5">
          Welcome to the Research & Development Department.
          <br>
          Here we put our energies to create trades that follows our higher vision of commerce.
        </div>
        <div class="submotto col-md-12 text-center p-5">
          <label>Contact us at rd@monteverdevallese.com</label>
        </div>
      </div>
      
</div>