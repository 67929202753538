


  <div class="page-header" style="background-color: #fff;">
    <!-- style="background-image: url('./assets/img/bg1.jpg');" -->
    <div class="container-fluid">
  
      <!-- <app-components></app-components> -->
    
  
    
  
      <!-- {{ selectedCategories | json }} -->
      <!-- <ng-select 
          (change)="toggleCategoryFilter()"    
          [items]="categories"
          bindLabel="name"
          placeholder="Filtra per"
          appendTo="body"
          multiple="true"
          [(ngModel)]="selectedCategories">
      </ng-select> -->
  
     <!-- <select (change)="toggleCategoryFilter($event)">
        <option *ngFor="let cat of categories" value="{{cat.catID}}" >{{cat.name}}</option>
     </select> -->
  
      <div class="row">
  
        <!-- {{categories | json}} -->
        <div class="categories-legend">
          <ul class="breadcrumbs">
            <li class="bread-item">
                <!-- [routerLinkActive]="['is-active']" -->
                <a [routerLink]="['/home']" class="bread-link">
                  <i class="fas fa-home"></i>
                  <!-- <p>Home</p> -->
                </a>
            </li>
            <li class="bread-item">
                <a class="bread-link">
                    <!-- <i class="fas fa-ellipsis-h"></i> -->
                  <p>{{"CONTACTS_BREAD" | translate}}</p>
                </a>
            </li>
        </ul>
        <div class="p-category-breadcrumbs">
            <!-- <p style="display: inline-block;margin-right: 10px;color: #000;">
              Filtra per: 
            </p> -->
          <!-- <app-partners 
            class="p-breadcrumbs" 
            [partnersIDs]="getAllPartners()"></app-partners> -->
        </div>
        </div>
  
  
  
        <!-- Left Column / Headphones Image -->
        <div class="col-md-6 left-column">
  
          
          <!-- Product Description -->
          <div class="product-description" >
            <h1>{{"CONTACTS_TITLE" | translate}}</h1>
            <!-- <product-categories class="p-categories" [categoriesIDs]="product.categories"></product-categories> -->
            <div class="p-label">
              <p>{{"CONTACTS_SUBTITLE" | translate}}</p>
            </div>
            <hr>
            <p class="c-title">{{"CONTACTS_ADDRESS_TITLE" | translate}}</p>
            <p>{{"CONTACTS_ADDRESS1" | translate}}</p>
            <p>{{"CONTACTS_ADDRESS2" | translate}}</p>
            <!-- <br> -->
            <br>
            <p class="c-title">{{"CONTACTS_PHONE_TITLE" | translate}}</p>
            <p><a href="tel:0039010561914">{{"CONTACTS_PHONE" | translate}}</a></p>
            <!-- <br> -->
            <br>
            <p class="c-title">{{"CONTACTS_EMAIL_TITLE" | translate}}</p>
            <p>{{"CONTACTS_EMAIL_SUBTITLE" | translate}}</p>
            <p><a href="mailto:orlandi.moval@gmail.com">{{"CONTACTS_EMAIL" | translate}}</a></p>
            <!-- <br> -->
            <br>
            <p class="c-title">{{"CONTACTS_LINKS_TITLE" | translate}}</p>
            <p>
              {{"CONTACTS_LINKS1_TITLE" | translate}}  - <a href="http://www.ge.camcom.it" target="_blank">{{"CONTACTS_LINKS1_URL" | translate}}</a>
            </p>
            <p>
              {{"CONTACTS_LINKS2_TITLE" | translate}}  - <a href="https://www.alce-liguria.it" target="_blank">{{"CONTACTS_LINKS2_URL" | translate}}</a>
            </p>
            <p>
              {{"CONTACTS_LINKS3_TITLE" | translate}}   - <a href="https://en.seafood.no" target="_blank">{{"CONTACTS_LINKS3_URL" | translate}}</a>
            </p>
            <br>
            <p>{{"CONTACTS_INFO1" | translate}}</p>
            <p>{{"CONTACTS_INFO2" | translate}}</p>
          </div>
  
          <!-- <div class="p-labels">
            <product-categories class="p-categories" [categoriesIDs]="product.categories" [withName]="true"></product-categories>
            <div class="p-label">
              <p>Origine: {{product.origin}}</p>
            </div>
          </div> -->
        </div>
  
        <!-- Right Column -->
        <div class="col-md-6 right-column"> 
         <div class="right-int-box">
            <!-- <h1>Scrivici</h1> -->
            <br>
            <p>{{"CONTACTS_WRITEUS_TITLE" | translate}}</p>
            <hr>

          <form #form="ngForm" (ngSubmit)="inviaMail(form)">
            <fieldset>
              <!-- {{form | json}} -->
              <div class="col-md-12">
                <p class="c-title"tkey="name">{{"CONTACTS_WRITEUS_NAME" | translate}}</p>
                <div class="form-group">
                  <input class="form-control" name="name" type="text" ngModel />
                </div>
                <!-- <input type="text" [ngModel]="contatti.name"  /> -->
              </div>
              <div class="col-md-12">
                <p class="c-title"tkey="email">{{"CONTACTS_WRITEUS_EMAIL" | translate}}</p>
                <div class="form-group">
                  <input name="email" class="form-control" type="email" ngModel />
                </div>
              </div>
              <div class="col-md-12">
                <p class="c-title"tkey="phone">{{"CONTACTS_WRITEUS_PHONE" | translate}}</p>
                <div class="form-group">
                  <input name="phone" class="form-control" type="tel" ngModel />
                </div>
              </div>
         
          <!--   
              <div class="col-md-12">
                <p class="c-title"tkey="kidsPrev">Bambini</p>
                <div class="selector">
                  <select id="children">
                    <option value="0" selected>0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="2">3</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-12">
                <p class="c-title"tkey="notes">{{"CONTACTS_WRITEUS_TEXT" | translate}}</p>
                <!-- <input name="notes" type="text" ngModel /> -->
                <div class="form-group">
                  <textarea class="form-control" name="notes" ngModel>{{"CONTACTS_WRITEUS_TEXT_PLACEHOLDER" | translate}}</textarea>
                </div>
              </div>
              <!-- <div class="col-lg-4 col-md-4 col-sm-4" style="padding-top: 40px;">
          
                  <p class="lbl-container" style="float: left;">Privacy
                    <input type="checkbox" name="privacy" id="privacy" value="1">
                    <span class="checkmark"></span>
                  </p>
                  <a target="_blank" href="privacy-policy.html">
                    <p style="color:red !important;font-weight:700;margin-left: 5px;cursor: pointer;">* required</p>
                  </a>
                   <p for="privacy" tkey="notesPrev">Privacy</p>
                  <textarea name="privacy" id="privacy"></textarea> 
                </div> -->
              <div class="col-lg-12 col-md-12 col-sm-12 centered">
                <div class="button full-width">
                  <button type="submit"><img alt="send" src="/assets/img/icons/send.png"></button>
                  <!-- <div class="submit" (click)="inviaMail()" >INVIA</div> -->
                </div>
              </div>
              <div class="col-lg-12 centered">
                <div class="message-holder"><span id="message"></span></div>
              </div>
               
            </fieldset>
          </form>
        


         </div>
        </div>
     
    </div>
  </div>
  
  <!-- <app-loading-screen></app-loading-screen> -->
  
  
