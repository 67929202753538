<main class="contenitor">
  <div class="row">

    <div *ngIf="product" class="categories-legend">
      <div class="back-arrow only-xs">
        <a [routerLink]="['/products']" [routerLinkActive]="['is-active']" class="bread-link">
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>
      <ul class="breadcrumbs  only-product">
        <li class="bread-item">
            <!-- [routerLinkActive]="['is-active']" -->
            <a [routerLink]="['/home']" class="bread-link">
                <i class="fas fa-home"></i>
                <!-- <p>Home</p> -->
            </a>
        </li>
        <li class="bread-item">
            <a [routerLink]="['/products']" [routerLinkActive]="['is-active']" class="bread-link">
                <!-- <i class="fas fa-ellipsis-h"></i> -->
              <p>{{"PRODUCTS" | translate}}</p>
            </a>
        </li>
        <li class="bread-item">
          <a class="is-active" class="bread-link">
              <!-- <i class="fas fa-ellipsis-h"></i> -->
            <p>{{product[currentLang].name}}</p>
          </a>
      </li>
    </ul>
    <div class="p-category-breadcrumbs">
      <product-categories class="p-breadcrumbs" [categoriesIDs]="product.categories" [withName]="true" [isProduct]="true"></product-categories>
    </div>
    </div>


  <!-- Left Column / Headphones Image -->
  <div class="col-md-6 left-column" *ngIf="product && product.images && p_images" >

      <!-- <ng-image-slider
        class="text-lg-center" 
        [manageImageRatio]="true"
        [imageSize]="!mobileCheck() ? {width: '650px', height: '60%', space: 4} : {width: '200px', height: '50%', space: 1}"
        [imagePopup]="true"
        [slideImage]="1" 
        [animationSpeed]="0.4" 
        [infinite]="product.images.length == 1 ? false : true" 
        [images]="p_images" 
        #nav>
      </ng-image-slider> -->

      <ngb-carousel *ngIf="p_images" 
          [showNavigationArrows]="p_images && p_images.length > 1" 
          [showNavigationIndicators]="false" 
          [interval]="5000" 
          [pauseOnHover]="true">

        <ng-template ngbSlide *ngFor="let image of p_images">
          <div class="img-wrapper" >
             <!-- *ngIf="!mobileCheck()" -->
            <div class="center-img" [style.backgroundImage]="'url('+ image.image + ')'"></div>
            <!-- <img [src]="image.image" class="center-img" alt="{{'Immagine di ' + product.name}}"> -->
          </div>
          <!-- <div class="img-wrapper" style="height: 40vh;border-radius: 6px;background-size: contain;background-repeat: no-repeat;background-position: center;" *ngIf="mobileCheck()" [style.backgroundImage]="'url('+ image.image+')'">
           <img [src]="image.image" alt="{{'Immagine di ' + product.name}}">
          </div> -->
          <!-- <div class="carousel-caption">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div> -->
        </ng-template>
      </ngb-carousel>
      


    <!-- <div class="p-labels">
      <product-categories class="p-categories" [categoriesIDs]="product.categories" [withName]="true"></product-categories>
      <div class="p-label">
        <p>Origine: {{product.origin}}</p>
      </div>
    </div> -->
  </div>
 
  <!-- Right Column -->
  <div class="col-md-6 right-column">
 
    <!-- Product Description -->
    <div class="product-description" *ngIf="product && currentLang" >
      <h1>{{product[currentLang].name}}</h1>
      <!-- <product-categories class="p-categories" [categoriesIDs]="product.categories"></product-categories> -->
      <div class="p-label">
        <p>Origine: {{product[currentLang].origin}}</p>
      </div>
      <hr>
      <p  [innerHTML]="product[currentLang].description"></p>
      <hr *ngIf="product.certifications && product.certifications.length > 0">
      <product-certifications [certificationsIDs]="product.certifications"></product-certifications>
    </div>
 
    <!-- Product Configuration -->
    <!-- <div class="product-configuration">
 
      <div class="cable-config">
        <span>Cable configuration</span>
 
        <div class="cable-choose">
          <button>Straight</button>
          <button>Coiled</button>
          <button>Long-coiled</button>
        </div>
 
        <a href="#">How to configurate your headphones</a>
      </div>
    </div> -->
 
    <!-- Product Pricing -->
    <!-- <div class="product-price">
      <span>148$</span>
      <a href="#" class="cart-btn">Add to cart</a>
    </div> -->
  </div>
</div>

</main>