import { Component, OnInit } from '@angular/core';
import { ContattiService } from '../contatti.service';
import { Contatti } from 'definitions';
import swal from 'sweetalert2'; 
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  contatti: Contatti = new Contatti();
  name: string;
  email: string;
  phone: string;
  notes: string;

  constructor(
    private contattiService: ContattiService,
    private title: Title, 
    private meta: Meta) { }

  ngOnInit() {
    this.contatti = new Contatti();

    this.title.setTitle('Contatti | Moval');
    this.meta.updateTag({ name: 'description', content: "Contattaci per qualsiasi informazione" });
  
  }

  inviaMail(form) {
    this.contatti.name = form.value.name;
    this.contatti.email = form.value.email;
    this.contatti.phone = form.value.phone;
    this.contatti.notes = form.value.notes;
    console.log(this.contatti);
    let response = this.contattiService.performGetEx(this.contatti).toPromise();
    swal.fire('Grazie di averci contattato', 'Ti risponderemo nel minor tempo possibile!', 'success');
  }

}
