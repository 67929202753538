import { ProductDetailComponent } from './shared/product-contenitor/product-detail/product-detail.component';
import { ProductsComponent } from './shared/product-contenitor/products/products.component';
import { LandingComponent } from './shared/landing/landing.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactsComponent } from './shared/contacts/contacts/contacts.component';
import { AboutComponent } from './shared/about/about.component';
import { RdComponent } from './shared/rd/rd.component';

// import { ComponentsComponent } from './components/components.component';
// import { ProfileComponent } from './examples/profile/profile.component';
// import { SignupComponent } from './examples/signup/signup.component';
// import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';


const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    // { path: 'component',             component: ComponentsComponent },
    // { path: 'user-profile',     component: ProfileComponent },
    // { path: 'nucleoicons',      component: NucleoiconsComponent },
    { path: 'product/:id',         component: ProductDetailComponent },
    { path: 'about',           component: AboutComponent },
    { path: 'researchdevelopment', component: RdComponent },
    { path: 'contacts',           component: ContactsComponent },
    { path: 'products',           component: ProductsComponent },
    { path: 'home',          component: LandingComponent }
];

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
