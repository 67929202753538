import { Component, OnInit, Input, Output } from '@angular/core';
import { ProductCertification } from 'definitions';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from 'app/services/translate.service';

@Component({
  selector: 'product-certifications',
  templateUrl: './product-certifications.component.html',
  styleUrls: ['./product-certifications.component.css']
})
export class ProductCertificationsComponent implements OnInit {
  @Input() certificationsIDs: number[];
  // @Output() selectedCategory = new EventEmitter<ProductCategory>();
  certifications: ProductCertification[];
  count = 0;
  currentLang: string;

  constructor(private http: HttpClient, private langService: TranslateService) {
    this.certifications = [];
  }

  ngOnInit() {
    this.currentLang = this.langService.getCurrentLang();

    // console.log(">>> withName 1:", this.withName);
    this.getProductCertifications().subscribe(data => {
      // console.log('certs', data);
      this.filterCertifications(data.productCertifications)
    });
  }

  filterCertifications(productCertifications) {
    for (let index = 0; index < this.certificationsIDs.length; index++) {
      const element = productCertifications.filter(x => x.certID === this.certificationsIDs[index])[0];
      if (element) {
        this.certifications.push(element);
      }
    }
    this.count = this.certifications.length;
    // this.certifications = productCertifications;
  }

  getProductCertifications(): Observable<any> {
    return this.http.get('./assets/jdata/product-certifications.json');
  } 
  
  getBackgroundImage(cert: ProductCertification, index: number) {
    return '/assets/img/certifications/' + cert.imagePath;
  }

}
