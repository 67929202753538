<footer class="footer">
        <div class="row">
            <!-- <nav class="footer-nav">
                <ul>
                    <li></li>
                </ul>
            </nav> -->
            <div class="credits ml-auto">
                <span class="copyright">{{"FOOTER_1" | translate}}</span>
                <span class="copyright trv">
                    © {{test | date: 'yyyy'}} Monteverde & Vallese, by Giorgio Traverso
                </span>
            </div>
        </div>
</footer>
