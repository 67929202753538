<a [routerLink]="['/product', product ? product.prodID : 0 ]" class="btn-main-action">
  <div class="card-parent">
    <div class="card" [style.backgroundImage]="'url('+ getBackgroundImage() + ')'">
      <!-- <img class="card-img-top" [src]="" alt="..."> -->
      <div class="card-body">


        <div class="label-box">
          <div class="card-name">
            <h3>{{product ? product[currentLang].name : ''}}</h3>
          </div>
          <product-categories class="categories" [categoriesIDs]="product.categories"></product-categories>
        </div>
    
        <!-- <span class="card-category">{{getProductCategoris(product.categories)}}</span> -->
        <!-- <p class="card-text">{{product.description}}</p> -->
          <!-- Scopri di più -->
      </div>
    </div>
  </div>
</a>

