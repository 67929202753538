import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var $:any;

@Component({
  selector: 'app-rd',
  templateUrl: './rd.component.html',
  styleUrls: ['./rd.component.css']
})
export class RdComponent implements OnInit {
  where_images: Array<object>;
  rdBackground: string;
  interval;
  imgNum = 1;

  constructor(
    private http: HttpClient, 
    private title: Title, 
    private meta: Meta) { // , private activatedRoute: ActivatedRoute 
    // this.activatedRoute.paramMap.subscribe(params => {
    //   this.prodID = +params.get('id');
    // });
  }

  ngOnInit() {
    this.title.setTitle('Research & Development | Moval');
    this.meta.updateTag({ name: 'description', content: "Welcome to the Research & Development Department. Here we put our energies to create trades that follows our higher vision of commerce." });
    this.rdBackground = this.changeBgImage();
    this.startTimer();
  }
  

  startTimer() {
      this.interval = setInterval(() => {
        this.imgNum = this.imgNum+1;
        if(this.imgNum > 9) {
          this.imgNum = 1;
        }
        this.rdBackground = this.changeBgImage();
    }, 3000);
  }

  changeBgImage() {
    // console.log("img n. ", Math.floor(Math.random()*27));
    return "url('./assets/img/rdlogos/" + this.imgNum + ".png')";
  }

}
