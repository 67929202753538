import { Injectable } from '@angular/core';

@Injectable()
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  public comingSoon = false;
  
  public API_ENDPOINT = '';
  public APP_BASE_HREF = '';
  public HP_BACKGROUND_TIMER = 30000;
  // Whether or not to enable debug mode
  public enableDebug = false;

  constructor() { }
}
