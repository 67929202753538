import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  
  localStorage: Storage;

  defaultLang = "it";

  constructor() {
    this.localStorage = window.localStorage;
  }

  getCurrentLang() {
    let storeLang = this.getItemStorage("lang");
    // console.log("current lang ", storeLang || this.defaultLang);
    return storeLang || this.defaultLang;
  }
  setLang(selectedLang: any) {
    this.setItemStorage("lang", selectedLang);
  }

  getItemStorage(key: string): any {
      return JSON.parse(this.localStorage.getItem(key));
  }
  
  removeItemStorage(key: string): boolean {
      this.localStorage.removeItem(key);
      return true;
  }

  setItemStorage(key: string, value: any): boolean {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
  }

}
