import { Component, OnInit,  ViewChild } from '@angular/core';
// import { Router, NavigationEnd } from '@angular/router';
// import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
// import { DOCUMENT } from '@angular/platform-browser';
import { NavbarComponent } from './shared/navbar/navbar.component';
// import { environment } from '../environments/environment'
import { EnvService } from './env.service';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    // private _router: Subscription;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;
    isComingSoonEnabled = this.env.comingSoon;
    // tslint:disable-next-line: max-line-length
    // constructor( private renderer: Renderer, private router: Router, @Inject(DOCUMENT, ) private document: any, private element: ElementRef, public location: Location) {}
    constructor(
        private env: EnvService) 
    {
      
    }

    onActivate(event) {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }
    
   
    ngOnInit() {
        // custom-navbar
            
        // var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
        // this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        //     console.log("Event from app component", event);
        //     if (window.outerWidth > 991) {
        //         window.document.children[0].scrollTop = 0;
        //     }else{
        //         window.document.activeElement.scrollTop = 0;
        //     }
        //     this.navbar.sidebarClose();
        // });
        // this.renderer.listenGlobal('window', 'scroll', (event) => {
        //     const number = window.scrollY;
        //     if (number > 150 || window.pageYOffset > 150) {
        //         // add logic
        //         navbar.classList.remove('navbar-transparent');
        //     } else {
        //         // remove logic
        //         navbar.classList.add('navbar-transparent');
        //     }
        // });
        // var ua = window.navigator.userAgent;
        // var trident = ua.indexOf('Trident/');
        // if (trident > 0) {
        //     // IE 11 => return version number
        //     var rv = ua.indexOf('rv:');
        //     var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        // }
        // if (version) {
        //     var body = document.getElementsByTagName('body')[0];
        //     body.classList.add('ie-background');

        // }

    }
    
    // removeFooter() {
    //     var titlee = this.location.prepareExternalUrl(this.location.path());
    //     titlee = titlee.slice( 1 );
    //     if(titlee === 'signup' || titlee === 'nucleoicons'){
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // }
}
