import { Component, Inject, OnInit } from '@angular/core';
import { EnvService } from 'app/env.service';
import { Location, LocationStrategy, PathLocationStrategy, DOCUMENT} from '@angular/common';
import { trigger,state,style,transition,animate } from '@angular/animations';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '../../services/translate.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css'],
    animations: [
      trigger('fadeInOut', [
        state('void', style({
          opacity: 0
        })),
        transition('void <=> *', animate(1000)),
      ]),
    ]
})

export class LandingComponent implements OnInit {
  timer = 30000;
  interval;
  homeBackground: string;
  lang: string;

  constructor(
    private env: EnvService, 
    public location: Location, 
    private langService: TranslateService, 
    private title: Title,
    private http: HttpClient,
    private meta: Meta,
    @Inject(DOCUMENT) private _document: Document) {
    this.timer = env.HP_BACKGROUND_TIMER;
    this.lang = this.langService.getCurrentLang();
    this.setLanguagesCache();    
    // this.baseUrl = env.APP_BASE_HREF;
  }

  ngOnInit() {
    this.title.setTitle('Monteverde & Vallese | International Fish Broker');
    this.meta.updateTag({ name: 'description', content: "Monteverde & Vallese | International Fish Broker" });

    this.homeBackground = this.changeBgImage();
    this.startTimer();
  }
  

  startTimer() {
      this.interval = setInterval(() => {
      this.homeBackground = this.changeBgImage();
    }, this.timer);
  }

  changeBgImage() {
    // console.log("img n. ", Math.floor(Math.random()*27));
    return "url('./assets/img/home-background/" + Math.floor(Math.random()*27) + ".jpg')";
  }

  isHome() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }
      if (titlee === '/home') {
          return true;
      } else {
          return false;
      }
  }

  setLanguage(selectedLang) {
    if(this.lang != selectedLang) {
        this.langService.setLang(selectedLang);
        this.lang = selectedLang;
        this.setLanguagesCache();
        setTimeout(() => {
            this._document.defaultView.location.reload();        
        }, 100);
    }
  }

  setLanguagesCache() {
    this.getTranslations().subscribe((data) => {
        // console.log('translations', data);
        let k=Object.keys(data);
        for(let i=0;i<k.length;i++) {
            // console.log("key", k[i], "value", data[k[i]]);
            this.langService.setItemStorage(k[i], data[k[i]]);
        }
    }, (error) => {
        console.log("set Language error", error);
      }, () => {
        // console.log(".");
      });
  }

  getTranslations(): Observable<any> {
    return this.http.get('./assets/jdata/language/' + this.lang + '.json');
  }

  // pauseTimer() {
  //   clearInterval(this.interval);
  // }


}
