<div class="page-header" style="background-color: #fff;margin-bottom: 15vh;">
  <!-- style="background-image: url('./assets/img/bg1.jpg');" -->
  <div class="container-fluid">

    <!-- <app-components></app-components> -->

    <!-- {{categories | json}} -->
    <div *ngIf="categories && categories.length > 0" class="categories-legend">
        <ul class="breadcrumbs only-product">
          <li class="bread-item">
              <!-- [routerLinkActive]="['is-active']" -->
              <a [routerLink]="['/home']" class="bread-link">
                <i class="fas fa-home"></i>
                <!-- <p>Home</p> -->
              </a>
          </li>
          <li class="bread-item">
              <a class="bread-link">
                  <!-- <i class="fas fa-ellipsis-h"></i> -->
                <p>{{"PRODUCTS" | translate}}</p>
              </a>
          </li>
      </ul>
      <div class="p-category-breadcrumbs">
          <p style="display: inline-block;margin-right: 10px;color: #000;">
            {{"FILTER_BY" | translate}}
          </p>
        <product-categories 
          class="p-breadcrumbs" 
          [categoriesIDs]="getAllCategories()" 
          [withName]="true"
          (selectedCategory)="toggleCategoryFilter($event)"></product-categories>
      </div>
    </div>

    <!-- {{ selectedCategories | json }} -->
    <!-- <ng-select 
        (change)="toggleCategoryFilter()"    
        [items]="categories"
        bindLabel="name"
        placeholder="Filtra per"
        appendTo="body"
        multiple="true"
        [(ngModel)]="selectedCategories">
    </ng-select> -->

   <!-- <select (change)="toggleCategoryFilter($event)">
      <option *ngFor="let cat of categories" value="{{cat.catID}}" >{{cat.name}}</option>
   </select> -->

    <div class="row product-list">


      <app-product-card class="col-md-4 col-sm-6 col-xs-12"
        *ngFor="let product of products 
        | filterProducts: selectedCategories 
        | paginate: { itemsPerPage: 20, currentPage: page }" 
        [product]="product" 
        (callbackProductItem)="callbackProductItem($event)">
      </app-product-card>

    <pagination-controls 
      class="custom-pagination"
      nextLabel=">>"
      previousLabel="<<"
      responsive="true"
      autoHide="true"
      maxSize="7"
      (pageBoundsCorrection)="pageChanged($event)"
      (pageChange)="pageChanged($event)">
    </pagination-controls>

    </div>


   
  </div>
</div>

<!-- <app-loading-screen></app-loading-screen> -->

