<main class="contenitor">
    <div class="row">

      <div class="categories-legend">
        <ul class="breadcrumbs">
          <li class="bread-item">
              <!-- [routerLinkActive]="['is-active']" -->
              <a [routerLink]="['/home']" class="bread-link">
                <i class="fas fa-home"></i>
                <!-- <p>Home</p> -->
              </a>
          </li>
          <li class="bread-item">
              <a class="bread-link">
                  <!-- <i class="fas fa-ellipsis-h"></i> -->
                <p>{{"ABOUT_BREAD" | translate}}</p>
              </a>
          </li>
      </ul>
      <div class="p-category-breadcrumbs">
          <!-- <p style="display: inline-block;margin-right: 10px;color: #000;">
            Filtra per: 
          </p> -->
          
          <!-- <div class="vl"></div>
        <app-partners 
          class="p-breadcrumbs" 
          [partnersIDs]="getAllPartners()"></app-partners> -->
      </div>
      </div>

    <!-- Left Column / Chi siamo Text -->
    <div class="col-md-6 text-column">

      <!-- Product Description -->
      <div class="product-description">
        <h1>{{"ABOUT_TITLE" | translate}}</h1>
        <!-- <product-categories class="p-categories" [categoriesIDs]="product.categories"></product-categories> -->
        <div class="p-label">
          <p>{{"ABOUT_SUBTITLE" | translate}}</p>
        </div>
        <hr>
        <p>
           {{"ABOUT_P1" | translate}}
        </p>

        <p>
          {{"ABOUT_P2" | translate}}
        </p>

        <p>
          {{"ABOUT_P3" | translate}}
        </p>
        
      </div>
  
    </div>
  
    <!-- Right Column / Chi siamo Images-->
    <div class="col-md-6 image-column">
        <ngb-carousel *ngIf="who_images" 
            [showNavigationArrows]="who_images && who_images.length > 1" 
            [showNavigationIndicators]="false" 
            [interval]="5000" 
            [pauseOnHover]="true">

          <ng-template ngbSlide *ngFor="let image of who_images">
            <div class="img-wrapper" >
              <!-- *ngIf="!mobileCheck()" -->
              <div class="center-img" [style.backgroundImage]="'url('+ image.image + ')'"></div>
              <!-- <img [src]="image.image" class="center-img" alt="{{'Immagine di ' + product.name}}"> -->
            </div>
            <!-- <div class="img-wrapper" style="height: 40vh;border-radius: 6px;background-size: contain;background-repeat: no-repeat;background-position: center;" *ngIf="mobileCheck()" [style.backgroundImage]="'url('+ image.image+')'">
            <img [src]="image.image" alt="{{'Immagine di ' + product.name}}">
            </div> -->
            <!-- <div class="carousel-caption">
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div> -->
          </ng-template>
        </ngb-carousel>
    </div>

    <!-- Center / Dove veniamo Text -->
    <div class="col-md-12 text-column who-text">
      
      <!-- Product Description -->
      <div class="product-description" >
        <h1>{{"WHERE_TITLE" | translate}}</h1>
        <div class="p-label">
          <p>{{"WHERE_SUBTITLE" | translate}}</p>
        </div>
        <hr>
        <p>
          {{"WHERE_P1" | translate}}
        </p>
        <p>
          {{"WHERE_P2" | translate}}
        </p>
        <p>
          {{"WHERE_P3" | translate}}
        </p>
        <p>
          {{"WHERE_P4" | translate}}
        </p>
      </div>
    </div>


    <!-- Left Column / Dove veniamo Text -->
    <div class="col-md-6 text-column where-text-1" >

      <!-- Product Description -->
      <div class="product-description" >
        <p>
          {{"WHERE_P5" | translate}}
        </p>
        <p>
          {{"WHERE_P6" | translate}}
        </p>
        
      </div>
    </div>

    <!-- Right Column / Dove veniamo Images -->
    <div class="col-md-6 image-column where-image">

      <ngb-carousel *ngIf="where_images" 
        [showNavigationArrows]="where_images && where_images.length > 1" 
        [showNavigationIndicators]="false" 
        [interval]="5000" 
        [pauseOnHover]="true">
          <ng-template ngbSlide *ngFor="let image of where_images">
            <div class="img-wrapper" >
              <!-- *ngIf="!mobileCheck()" -->
              <div class="center-img" [style.backgroundImage]="'url('+ image.image + ')'"></div>
              <!-- <img [src]="image.image" class="center-img" alt="{{'Immagine di ' + product.name}}"> -->
            </div>
            <!-- <div class="img-wrapper" style="height: 40vh;border-radius: 6px;background-size: contain;background-repeat: no-repeat;background-position: center;" *ngIf="mobileCheck()" [style.backgroundImage]="'url('+ image.image+')'">
            <img [src]="image.image" alt="{{'Immagine di ' + product.name}}">
            </div> -->
            <!-- <div class="carousel-caption">
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </div> -->
          </ng-template>
      </ngb-carousel>

    </div>
  
    <!-- Center / Dove veniamo Text -->
    <div class="col-md-12 text-column where-text" >
        
      <!-- Product Description -->
      <div class="product-description">
        <p>
          {{"WHERE_P7" | translate}}
        </p>
        <p>
          {{"WHERE_P8" | translate}}
        </p>
      </div>
    </div>
  

    
    <!-- Center / Partners Commerciali Text -->
    <div class="col-md-12 text-column who-text">
      
      <!-- Product Description -->
      <div class="product-description" >
        <h1>{{"PARTNERS_TITLE" | translate}}</h1>
        <div class="p-label">
          <p>{{"PARTNERS_SUBTITLE" | translate}}</p>
        </div>
        <hr>
        <p>
          {{"PARTNERS_P1" | translate}}
        </p>
        <p>
          {{"PARTNERS_P2" | translate}}
        </p>
      </div>


      <div class="col-md-12">
        <app-partners 
        class="loghi-partners" 
        [partnersIDs]="getAllPartners()"></app-partners> 
      </div>

    </div>
  </div>
</main>