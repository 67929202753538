import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductCategory } from 'definitions';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from 'app/services/translate.service';

@Component({
  selector: 'product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent implements OnInit {
  @Input() categoriesIDs: number[];
  @Input() withName?: boolean;
  @Input() isProduct?: boolean = false;
  @Output() selectedCategory = new EventEmitter<ProductCategory>();
  isFirst: boolean;
  categories: ProductCategory[];
  isSelected: Array<boolean>;
  isIncluded: Array<boolean>;
  currentLang: string;

  constructor(private http: HttpClient, private langService: TranslateService) {
    this.categories = [];
  }

  ngOnInit() {
    this.currentLang = this.langService.getCurrentLang();

    this.isFirst = true;
    // console.log(">>> withName 0:", this.withName);
    if(!this.withName) {
      this.withName = false;
    }
    // console.log(">>> withName 1:", this.withName);
    this.getProductCategories().subscribe(data => {
      // console.log('categories', data);
      this.resetAllIsSelected(data.productCategories);
      this.filterCategories(data.productCategories);
      this.setIncludedCategory(data.productCategories)
    });
  }
  resetAllIsSelected(productCategories: any) {
    this.isSelected = new Array<boolean>();
    for(let i = 0; i < productCategories.length; i++) {
      this.isSelected.push(false);
    }
  }
  activeAllIsSelected(productCategories: any) {
    this.isSelected = new Array<boolean>();
    for(let i = 0; i < productCategories.length; i++) {
      this.isSelected.push(true);
    }
  }
  getProductCategories(): Observable<any> {
    return this.http.get('./assets/jdata/product-categories.json');
  } 

  filterCategories(productCategories: any) {

    if(this.withName) {
      if (productCategories && this.categoriesIDs) {
        this.categories = productCategories;
      }
    }
    else
    {
      if (productCategories && this.categoriesIDs) {
        for (let index = 0; index < this.categoriesIDs.length; index++) {
          const element = productCategories.filter(x => x.catID === this.categoriesIDs[index])[0];
          if (element || this.withName) {
            this.categories.push(element);
          }
        }
      }
    }
  }

  selectCategory(category: ProductCategory, index: number) {
    let allInactive = true;
    let allActive = true;
    let countTrue = 0;
    this.isSelected[index] = !this.isSelected[index]; 

    for (const selected of this.isSelected) {
      if (selected === false) {
        allActive = false;
      }
    }
    for (const selected of this.isSelected) {
      if (selected === true) {
        countTrue++;
        allInactive = false;
      }
    }
    // if(this.isFirst || countTrue == 1) {
    //   this.resetAllIsSelected(this.categories);
    // }
    // else if(allInactive) {
    //   this.activeAllIsSelected(this.categories);
    // }
    this.isFirst = false;
    this.selectedCategory.emit(category);
  }

  setIncludedCategory(productCategories: any) {
    this.isIncluded = new Array<boolean>();
    for(let i = 0; i < productCategories.length; i++) {
      this.isIncluded.push(this.categoriesIDs.find(x => x == productCategories[i].catID) ? true : false);
    }
 
  }

  getBgColor(category: ProductCategory, index: number) {
    let bgcolor = '';
    if(!this.withName) {
      bgcolor = category.hexBgColor;
    }
    else {
      if(!this.isSelected[index]) {
        bgcolor = 'transparent';
      }
      else {
        bgcolor = category.hexBgColor;
      }
      if(this.isProduct && this.isIncluded[index]) {
          bgcolor = category.hexBgColor;
      }
    }
    return bgcolor;
  }

}
