import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { Partners } from 'definitions';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
declare var $:any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  @Input() partnersIDs: number[];
  partners: Partners[];
  who_images: Array<object>;
  where_images: Array<object>;
  
  sxScrollTop = 0;
  dxScrollTop;

  constructor(
    private http: HttpClient, 
    private title: Title, 
    private meta: Meta) { // , private activatedRoute: ActivatedRoute 
    // this.activatedRoute.paramMap.subscribe(params => {
    //   this.prodID = +params.get('id');
    // });
  }

  ngOnInit() {
    this.title.setTitle('Chi siamo | Moval');
    this.meta.updateTag({ name: 'description', content: "La nostra storia." });

    // $('.customer-logos').slick({
    //   slidesToShow: 6,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 1000,
    //   arrows: false,
    //   dots: false,
    //   pauseOnHover: false,
    //   responsive: [{
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 4
    //     }
    //   }, {
    //     breakpoint: 520,
    //     settings: {
    //       slidesToShow: 3
    //     }
    //   }]
    // });
    // this.sxScrollTop = 0;
    // this.dxScrollTop = $('.right-int-box').height()-600;
    // console.log("$('.right-column').innerHeight()", this.dxScrollTop);
    this.getAboutImages().subscribe(data => {
      // console.log('partners', data);
      this.who_images = new Array<object>();
      this.where_images = new Array<object>();
      for (const img of data.about) {
        img.section == 1 ? this.who_images.push({ image: "/assets/img/about/who/" + img.imagePath, thumbImage: "/assets/img/about/who/" + img.imagePath })
        : this.where_images.push({ image: "/assets/img/about/where/" + img.imagePath, thumbImage: "/assets/img/about/where/" + img.imagePath });
      }
    });

    this.getPartners().subscribe(data => {
      // console.log('partners', data);
      this.partners = data.partners;
    });
  }

  // @HostListener('mousewheel', ['$event']) // for window scroll events
  // onMousewheel(event) {
  //   let ua = navigator.userAgent;
  //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
  //   {}      
  //   else
  //   {

  //     // console.log(this.document.body.scrollTop);
  //     var w = window,
  //     d = document,
  //     e = d.documentElement,
  //     g = d.getElementsByTagName('body')[0],
  //     x = w.innerWidth || e.clientWidth || g.clientWidth,
  //     y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  //     let maxDxHeight = $('.right-int-box').height()-600;
  //     let maxSxHeight = $('.product-description').height();

  //     var delta = event.deltaY;
  //     if(delta > 0) {
        
  //         if(this.dxScrollTop >= 0) {
  //           this.dxScrollTop -= 10;
  //         }
  //         if(this.sxScrollTop < maxSxHeight) {
  //           this.sxScrollTop += 10;
  //       }
  //     } else if(delta < 0) {

  //         if(this.dxScrollTop <= maxDxHeight) {
  //           this.dxScrollTop += 10;
  //         }
  //         if(this.sxScrollTop > 0) {
  //           this.sxScrollTop -= 10;
  //       }
  //     }
  //     // for IE
  //     event.returnValue = false;
  //     // for Chrome and Firefox
  //     if(event.preventDefault) {
  //         event.preventDefault();
  //     }
  //   }
  // }

  getAllPartners() {
    // return this.partners.map(a => a.partnerID);
  }

  getPartners(): Observable<any> {
    return this.http.get('./assets/jdata/partners.json');
  }

  getAboutImages(): Observable<any> {
    return this.http.get('./assets/jdata/about.json');
  }
}
