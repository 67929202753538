import { Pipe } from '@angular/core';
import { Product, ProductCategory } from 'definitions';

@Pipe({
  name: 'filterProducts'
})
export class FilterProductsPipe {

  // transform(products: Product[], filterCatIDs?: ProductCategory[]): any {
  //   if (!products || products.length === 0 || !filterCatIDs || filterCatIDs.length === 0) {
  //     return products;
  //   }
  //   debugger;
  //   let p = [];
   


  //   if (!items || !filter) {
  //     return items;
  //   }
  //   // filter items array, items which match and return true will be kept, false will be filtered out
  //   for (const category of filterCatIDs) {
  //     p = p.concat(products.filter(x => x.categories.indexOf(category.catID) !== -1));
  //    }
  //    return p;

  // }

  transform(products: Product[], filterCatIDs?: ProductCategory[]): Product[] {
    // debugger
    if (!products || products.length === 0 || !filterCatIDs || filterCatIDs.length === 0) {
      return products;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return products.filter((product: Product) => this.applyFilter(product, filterCatIDs));
  }

  applyFilter(product: Product, filterCatIDs?: ProductCategory[]): boolean {
    let exists = false;
    for (let filter of filterCatIDs) {
          if (product.categories.indexOf(+filter.catID) !== -1) {
            exists = true;
          }
    }
    return exists;
  }
}
