import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from 'app/services/translate.service';
import { HttpClient } from '@angular/common/http';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  TRANSLATION: any;
  currentLang: string;

  constructor(private http: HttpClient, private langService: TranslateService) {
    
  }
  transform(value: string, args?: any) {
    this.currentLang = this.langService.getCurrentLang();
    this.TRANSLATION = this.langService.getItemStorage(value);
    // let translatedValue = this.TRANSLATION[value];
    return this.TRANSLATION;
  }
  getTranslations(): Observable<any> {
    return this.http.get('./assets/jdata/language/' + this.currentLang + '.json');
  }
}
