import { Component, OnInit, Input } from '@angular/core';
import { Partners } from 'definitions';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// declare var $: any;

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  @Input() partnersIDs: number[];
  partners: Partners[];
  
  constructor(private http: HttpClient) { 
    this.partners = [];
  }

  ngOnInit() {

    this.getPartners().subscribe(data => {
      console.log('partner', data);
      this.partners = data.partners;
      // setInterval(() => {
      //   this.partners = this.partners.concat(data.partners);
      // }, 6000);
    });
  }

  getPartners(): Observable<any> {
    return this.http.get('./assets/jdata/partners.json');
  } 

  getLogoImage(path: string) {
    return "/assets/img/partners/" + path;
  }

}
